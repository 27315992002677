module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Open Sans","Maven Pro:400,500,700,900"]}},
    },{
      plugin: require('/opt/build/repo/node_modules/@weknow/gatsby-remark-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1920,"showCaptions":true,"linkImagesToOriginal":false,"quality":90},
    },{
      plugin: require('/opt/build/repo/node_modules/@raae/gatsby-remark-oembed/gatsby-browser.js'),
      options: {"plugins":[],"providers":{"exclude":["Twitch","Flickr"]}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
